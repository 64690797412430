export default scope => {
  scope = scope ? ':' + scope : ''
  return {
    created() {
      window.Kvass.emit('created' + scope, this)
    },
    mounted() {
      window.Kvass.emit('mounted' + scope, this)
    },
  }
}
