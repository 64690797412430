import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { i18n } from '@/i18n.js'
import Loading from '@/views/Loading.vue'
import Page from '@/views/Page.vue'

import { ScrollToAnchor } from '@/utils'
Vue.use(VueMeta)
Vue.use(VueRouter)

function getRoutes() {
  let contactPath =
    '/' +
    i18n
      .t('getInTouch')
      .toLowerCase()
      .replace(/\s/g, '-')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

  return [
    {
      path: '/',
      name: 'Page',
      component: Page,
    },

    {
      path: contactPath,
      name: 'Contact',
      props: true,
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "contact" */ '@/views/Contact.vue')),
    },

    {
      path: '/:slug',
      name: 'Post',
      props: true,
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "post" */ '@/views/Post/Component.vue')),
    },
    {
      path: '/:postSlug/:slug',
      name: 'PostSubpage',
      props: true,
      component: () =>
        LazyLoadView(
          import(/* webpackChunkName: "post-subpage */ '@/views/Post/Subpages/Component.vue'),
        ),
    },
    {
      path: '*',
      redirect: {
        name: 'Page',
      },
    },
  ]
}

const routes = getRoutes()

function LazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: Loading,
    delay: 150,
    // error: require('@views/_timeout.vue').default,
    // timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return ScrollToAnchor(to.hash.substring(1))
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next(Object.assign({}, to, { query: from.query }))
  } else {
    next()
  }
})

export default router
