<template>
  <div class="footer__social-icons">
    <span v-for="entry in soMeEntries" :key="entry.type">
      <a v-if="entry.link" :href="entry.link" target="_blank">
        <FontAwesomeIcon :icon="entry.icon" size="1x" />
        <span class="footer__social-text">{{ entry.type }}</span>
      </a>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object],
    },
  },
  computed: {
    soMeEntries() {
      return [
        {
          type: 'Facebook',
          icon: ['fab', 'facebook'],
          link: this.value.facebook_url,
        },
        {
          type: 'Instagram',
          icon: ['fab', 'instagram'],
          link: this.value.instagram_url,
        },
        {
          type: 'Linkedin',
          icon: ['fab', 'linkedin'],
          link: this.value.linkedin_url,
        },
        {
          type: 'Twitter',
          icon: ['fab', 'twitter'],
          link: this.value.twitter_url,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  &__social-icons {
    @include respond-below('phone') {
      padding-block-start: 30px;
      display: inline-flex;
      flex-direction: column;
      gap: 20px;
    }
    .fa-1x {
      font-size: 1.5em;
    }
    a {
      margin-right: 0.8rem;
      color: inherit;
      display: inline-flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
  }
  &__social-text {
    @include respond-above('phone') {
      display: none;
    }
  }
}
</style>
