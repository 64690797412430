<template>
  <div class="error-handler">
    <FontAwesomeIcon :icon="['fal', 'exclamation-circle']" class="text--error" size="2x" />
    <div class="error-handler__title">{{ $t('couldNotLoadContent') }}</div>
    <i18n path="couldNotLoadContentMessage" tag="div">
      <a :href="`mailto:${email}`">{{ email }}</a>
    </i18n>
    <div class="g-1 mt-2">
      <ButtonComponent :label="$t('retry')" :icon="['fad', 'sync-alt']" @click="refresh" />
      <ButtonComponent
        :label="$t('showDetails')"
        :icon="['fad', 'angle-down']"
        @click="showDetails = !showDetails"
      />
    </div>
    <div v-if="showDetails" class="error-handler__details">
      {{ $attrs.error }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDetails: false,
    }
  },
  computed: {
    email() {
      return `support@${window.location.host.split('.').slice(1).join('.')}`
    },
  },
  methods: {
    refresh() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
.error-handler {
  max-width: 400px;
  text-align: center;

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }

  &__details {
    margin-top: 1rem;
    padding: 1rem;
    max-height: 200px;
    overflow-y: scroll;
    background-color: rgba($error, 0.2);
    border: 1px solid rgba($error, 0.2);
    border-radius: $border-radius;
  }
}
</style>
