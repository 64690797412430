import API from '@/api'

function getCoverText(title, content) {
  if (title) return `<h1>${title}</h1> ${content}`

  return content
}

export default function() {
  const get = path => this.$path(path, this.$store.state)

  function getSubpageSlug(source) {
    return (
      (
        (get('Root.item.posts') || []).find(
          post => post.customFieldsConfig?.[0]?.source === source,
        ) || {}
      ).slug || '/'
    )
  }

  return [
    {
      component: 'Slider',
      data: get('Page.item.customFields.cover'),
      props: {
        aspectRatio: '0',
        class: 'hero__slider',

        options: {
          manualNavigation: false,
        },
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            condition:
              get('Page.item.customFields.page-hero.title') ||
              get('Page.item.customFields.page-hero.content') ||
              (get('Page.item.customFields.page-hero-buttons') || []).filter(f => {
                return f.content && f.type
              }).length,
            props: {
              class: `hero__overlay hero__overlay-placement--${get(
                'Page.item.customFields.page-hero.placement',
              ) || 'center'}`,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            },
            blocks: {
              default: [
                {
                  component: 'Announcement',
                  props: {
                    class: 'announcement',
                  },
                  data: {
                    content: get('Page.item.customFields.announcement'),
                  },
                },
                {
                  component: 'Section',
                  props: {
                    size: 'large',
                    class: 'hero__overlay-content',
                    style: 'padding-block-end: 6rem',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Text',
                        condition:
                          get('Page.item.customFields.page-hero.title') ||
                          get('Page.item.customFields.page-hero.content'),
                        props: {
                          style: 'margin-bottom: 1rem',
                        },
                        data: {
                          content: getCoverText(
                            get('Page.item.customFields.page-hero.title'),
                            get('Page.item.customFields.page-hero.content'),
                          ),
                        },
                      },
                      {
                        component: 'Flex',
                        condition: (get('Page.item.customFields.page-hero-buttons') || []).filter(
                          f => {
                            return f.content && f.type
                          },
                        ).length,

                        props: {
                          class: 'hero__overlay-button-wrapper',
                          flexWrap: 'wrap',
                        },
                        blocks: {
                          default: (get('Page.item.customFields.page-hero-buttons') || [])
                            .filter(button => {
                              return Boolean(button.content)
                            })

                            .map(button => {
                              return {
                                component: 'Button',
                                data: {
                                  label: button.content,
                                  to: button.addSlug
                                    ? button.slug
                                    : button.type === 'contacts'
                                    ? { name: 'Contact' }
                                    : getSubpageSlug(button.type),
                                  theme: button.type === 'contacts' ? 'secondary' : 'primary',
                                  icon: button.type === 'contacts' ? '' : ['fas', 'arrow-right'],
                                  tag: 'router-link',
                                },
                              }
                            }),
                        },
                      },
                    ],
                  },
                },
                {
                  component: 'Stats',
                  condition: get('Page.item.customFields.key-info.0.value'),
                  data: {
                    content: get('Page.item.customFields.key-info'),
                  },
                  variant: 'text-over',
                  props: {
                    padding: '2rem',
                    theme: 'primary',

                    width: 'medium',
                    postion: 'center',
                  },
                },
              ],
            },
          },
        ],
      },
    },

    ...(get('Page.item.customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          showResponsivePadding: true,
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'inside',
        },
      }
    }),

    {
      component: 'Section',
      condition: get('Page.item.customFields.project-portal.max-items') === 'none' ? false : true,
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexWrap: 'wrap',
              alignItems: 'flex-end',
              gap: '1rem',
              style: 'padding-bottom: 2rem;',
              class: 'project-portal-wrapper',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    style: 'max-width: 1000px; margin: 0 auto',
                  },

                  data: {
                    content: get('Page.item.customFields.project-portal.content'),
                  },
                },
                {
                  component: 'Button',
                  condition: get('Page.item.customFields.project-portal.button-label'),
                  props: {
                    class: 'project-portal-button',
                  },
                  data: {
                    label: get('Page.item.customFields.project-portal.button-label'),
                    to: getSubpageSlug('project-overview'),
                    tag: 'router-link',
                  },
                },
              ],
            },
          },
          {
            component: 'Portal',
            variant: 'project',
            props: {
              api: API,
              filterMethod: get('Page.item.customFields.project-portal.filterMethod') || 'none',
              filterCategories:
                get('Page.item.customFields.project-portal.filterMethod') === 'category'
                  ? get('Page.item.customFields.project-portal.categories') || []
                  : [],
              theme: get('Page.item.customFields.project-portal.theme') || 'masonry',
              maxItems: parseInt(get('Page.item.customFields.project-portal.max-items')) || 3,
              layout: get('Page.item.customFields.project-portal.layout'),
              excluded: get('Page.item.customFields.project-portal.excluded-projects'),
            },
          },
        ],
      },
    },

    {
      condition: () => {
        return (get('Page.item.customFields.icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        width: 'medium',
        theme: get('Page.item.customFields.icons-settings.theme') || 'none',
        style:
          !get('Page.item.customFields.icons-settings.theme') ||
          get('Page.item.customFields.icons-settings.theme') === 'none'
            ? 'padding-top:0;'
            : '',
      },

      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'padding-block: 4rem; padding-top:2rem; margin: 0 auto;',
              class: 'text--medium',
            },
            data: {
              content: get('Page.item.customFields.competence-content'),
            },
          },
          {
            component: 'Features',
            props: {
              style: 'padding-bottom: 2rem',
              columns: parseInt(get('Page.item.customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get(
                'Page.item.customFields.icons-settings.show-icon-background-color',
              )
                ? get('Page.item.customFields.icons-settings.icon-background-color')
                : 'inherit',
              borderTheme: get('Page.item.customFields.icons-settings.border-theme'),
              placement: get('Page.item.customFields.icons-settings.placement'),
            },
            data: {
              content: get('Page.item.customFields.icons'),
            },
            variant: get('Page.item.customFields.icons-settings.variant') || 'none',
          },
        ],
      },
    },

    ...(get('Page.item.customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'inside',
        },
      }
    }),
    {
      component: 'Section',
      condition:
        get('Page.item.customFields.contact-cta.content') ||
        get('Page.item.customFields.contact-cta.button'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'contact-cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: get('Page.item.customFields.contact-cta.content'),
                  data: {
                    content: get('Page.item.customFields.contact-cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  condition: get('Page.item.customFields.contact-cta.button'),
                  data: {
                    label: get('Page.item.customFields.contact-cta.button'),
                    to: { name: 'Contact' },
                    theme: 'primary',
                    tag: 'router-link',
                    icon: ['fas', 'arrow-right'],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
