<template>
  <div class="announcement" v-if="content.content">
    <div class="announcement__text" v-html="content.content"></div>
    <div v-if="content.button">
      <ButtonComponent tag="a" :label="content.button" :href="content.url" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },

  computed: {
    content() {
      return this.data.content || {}
    },
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
  },
}
</script>

<style lang="scss">
.announcement {
  --text-color: white;
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.65);
  box-shadow: -1px 6px 40px 3px rgba(34, 34, 34, 0.25);
  backdrop-filter: blur(40px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0.3rem;
  z-index: get-layer('cta');
  @include body-font(16px);

  .elder-button {
    &__label {
      padding: 0.5rem 0.8rem;
    }
    &--default {
      background-color: white;
    }
  }

  &__text {
    color: var(--text-color);
  }
}
</style>
