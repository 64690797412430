import { GraphQLClient } from 'graphql-request'
import { getCookie } from '@/utils'
import Queries from './graphql/queries'
import Mutations from './graphql/mutations'

class API {
  constructor() {
    let params = new URLSearchParams(location.search)
    this.page = getCookie('kvass.page') || params.get('page-id')
  }

  get logo() {
    return `/api/page/${this.page}/logo`
  }

  get logoInverted() {
    return `/api/page/${this.page}/logo-inverted`
  }

  get favicon() {
    return `/api/page/${this.page}/favicon`
  }

  get pageId() {
    return this.page
  }

  request(...args) {
    return new GraphQLClient('/api/graphql').request(...args)
  }

  getPage(id) {
    if (!id) id = this.page
    return this.request(Queries.Page, { id }).then(data => data.Page)
  }

  getProjects(id) {
    if (!id) id = this.page
    return this.request(Queries.Projects, { id }).then(data => {
      return data.Projects
    })
  }

  getRoot(id) {
    if (!id) id = this.page

    return this.request(Queries.Root, { id }).then(data => data.Page)
  }

  getPost(id) {
    if (!id) id = this.page
    return this.request(Queries.Post, { id }).then(data => data.Page)
  }

  getPosts(id) {
    if (!id) id = this.page
    return this.request(Queries.Posts, { id }).then(data => data.Posts)
  }

  createLead(data) {
    return this.request(Mutations.LeadCreate, { data }).then(data => data.LeadCreate)
  }
}

export default new API()
